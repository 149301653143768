import mediaQueries from './media-queries-config.json';

// Const
// -----

window.SMALL_MOBILE_WIDTH = mediaQueries.mobile.small;
window.MOBILE_WIDTH = mediaQueries.mobile.portrait;
window.LANDSCAPE_MOBILE_WIDTH = mediaQueries.mobile.landscape;
window.PORTRAIT_TABLET_WIDTH = mediaQueries.tablet.portrait;
window.TABLET_WIDTH = mediaQueries.tablet.landscape;
window.SMALL_NOTEBOOK_WIDTH = mediaQueries.notebook.small;
window.NOTEBOOK_WIDTH = mediaQueries.notebook.normal;

window.HEADER_HEIGHT = $('.header').height();

// selectors
window.$WINDOW = $(window);
window.$DOCUMENT = $(document);
window.$HTML = $(document.documentElement);
window.$BODY = $(document.body);

// tosrus default settings
window.TOSRUS_DEFAULTS = {
  buttons: {
    next: true,
    prev: true
  },

  keys: {
    prev: 37,
    next: 39,
    close: 27
  },

  wrapper: {
    onClick: 'close'
  }
};


// Helpers
// -------

window.WINDOW_WIDTH = window.innerWidth || $WINDOW.width();
window.WINDOW_HEIGHT = $WINDOW.height();
$WINDOW.resize(function() {
  WINDOW_WIDTH = window.innerWidth || $WINDOW.width();
  WINDOW_HEIGHT = $WINDOW.height();
});

window.IS_DESKTOP_WIDTH = function() {
  return WINDOW_WIDTH > NOTEBOOK_WIDTH;
};
window.IS_NOTEBOOK_WIDTH = function() {
  return ( WINDOW_WIDTH > SMALL_NOTEBOOK_WIDTH && WINDOW_WIDTH <= NOTEBOOK_WIDTH );
};
window.IS_SMALL_NOTEBOOK_WIDTH = function() {
  return ( WINDOW_WIDTH > TABLET_WIDTH && WINDOW_WIDTH <= SMALL_NOTEBOOK_WIDTH );
};
window.IS_TABLET_WIDTH = function() {
  return ( WINDOW_WIDTH >= PORTRAIT_TABLET_WIDTH && WINDOW_WIDTH <= TABLET_WIDTH );
};
window.IS_MOBILE_WIDTH = function() {
  return WINDOW_WIDTH <= MOBILE_WIDTH;
};
window.IS_LANDSCAPE_MOBILE_WIDTH = function() {
  return WINDOW_WIDTH <= LANDSCAPE_MOBILE_WIDTH;
};
window.IS_SMALL_MOBILE_WIDTH = function() {
  return WINDOW_WIDTH <= SMALL_MOBILE_WIDTH;
};
window.IS_TOUCH_DEVICE = 'ontouchstart' in document;


// Masked input
// ------------

if (IS_DESKTOP) {
  $('input[type="date"]').attr('type', 'text');

  // Phone
  $('input[type="tel"]').mask('+7 (999) 999-99-99', {
    autoclear: false
  });

  // Date
  $('.js-date-mask').mask('99/99/9999', {
    placeholder: 'дд.мм.гггг',
    autoclear: false
  });

  // Time
  $('.js-time-mask').mask('99:99', {
    placeholder: 'чч:мм',
    autoclear: false
  });
}


// Spinner
// -------

$DOCUMENT.on('mousedown.js-spinner', '.js-spinner-down, .js-spinner-up', function() {
  var $spinnerControl = $(this),
      $input = $spinnerControl.siblings('.inp'),
      value = parseInt( $input.val(), 10 ),
      step = $input.data('step') ? $input.data('step') : 1,
      mayBeZero = $input.data('zero') ? $input.data('zero') : false,
      incTimeout, incInterval, decTimeout, decInterval;

  $spinnerControl
    .on('mouseup.js-spinner', clearAll)
    .on('mouseleave.js-spinner', $spinnerControl, clearAll);

  if ( $spinnerControl.hasClass('js-spinner-down') ) {
    decVal(); decTimeout = setTimeout(function() {
      decInterval = setInterval(decVal, 70);
    }, 300);
  }

  if ( $spinnerControl.hasClass('js-spinner-up') ) {
    incVal(); incTimeout = setTimeout(function() {
      incInterval = setInterval(incVal, 70);
    }, 300);
  }

  function incVal() {
    if ( $.isMouseLoaderActive() ) return;

    $input.val(value + step).change();
  }

  function decVal() {
    if ( $.isMouseLoaderActive() ) return;

    if ( mayBeZero ) {
      if ( value >= step ) {
        $input.val(value - step).change();
      }
    } else {
      if ( value > step ) {
        $input.val(value - step).change();
      }
    }
  }

  function clearAll() {
    clearTimeout(decTimeout); clearInterval(decInterval);
    clearTimeout(incTimeout); clearInterval(incInterval);
  }
});

$DOCUMENT.on('keydown', '.js-spinner .inp', function(e) {
  var $input = $(this);

  if ( e.keyCode == 46 || e.keyCode == 8 || e.keyCode == 9 || e.keyCode == 27 ||
    (e.keyCode == 65 && e.ctrlKey === true) ||
    (e.keyCode >= 35 && e.keyCode <= 39)) {
    return;
  } else {
    if ( (e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105 ) ) {
      e.preventDefault();
    }
  }
});

$DOCUMENT.on('keyup paste', '.js-spinner .inp', function(e) {
  var $input = $(this),
      mayBeZero = $input.data('zero') ? $input.data('zero') : false;

  if ( !mayBeZero && $input.val() == 0 ) {
    $input.val(1);
  }
});


// Overlay loader
// --------------

// open popup (GET-parameters are allowed)
$DOCUMENT.on('click.overlay-open', '.js-overlay', function(e) {
  e.preventDefault();

  var href = $(this).attr('href');
  var strings = href.split("?");
  var hash = strings[0].replace('/', '');
  var query = '';
  if (strings.length > 1){ query = strings[1]; }

  var $popup = hash;
  $.overlayLoader(true, {
    node: $popup,
    hideSelector: '.js-popup-close'
  });
});

// fire popup by its #hash (without GET-parameters)
$DOCUMENT.ready(function () {
  var hash = window.location.hash;
  if (hash == '') return;
  if (hash.substr(hash.length - 5) != 'popup') return;

  var $popup = hash;
  $.overlayLoader(true, {
    node: $popup,
    hideSelector: '.js-popup-close'
  });
});

// autofocus
$DOCUMENT.on('overlayLoaderShow', function(e, $node) {
  $node.find('.js-autofocus-inp').focus();
});


// Selectric
// ---------

// init selectric
$DOCUMENT.on('initSelectric yiiListViewUpdated', function() {
  $('select').selectric({
    disableOnMobile: false,
    nativeOnMobile: true
  });
}).trigger('initSelectric');


// Checkboxes
// ----------

$('.checkbox input').on('change initCheckboxes', function() {
  var $inp = $(this),
      $label = $inp.closest('.checkbox');

  if ( $inp.prop('checked') ) {
    $label.addClass('checked');
  } else {
    $label.removeClass('checked');
  }
}).trigger('initCheckboxes');


// Radio buttons
// ----------

$('.radio input').on('change initRadio', function() {
  var $inp = $(this),
      $group = $('[name="' + $inp.attr('name') + '"]'),
      $labels = $group.closest('.radio'),
      $selectedItem = $labels.find('input').filter(':checked').closest('.radio');

  $labels.removeClass('checked');
  $selectedItem.addClass('checked');
}).trigger('initRadio');


// Scroll to
// ---------

$DOCUMENT.on('click.scroll-to', '.js-scroll-to', function(e) {
  e.preventDefault();

  var $lnk = $(this);
  var $elemToScroll = $(this)[0].hash;
  var speed = $lnk.data('speed') || 450;
  var offset = $lnk.data('offset') || 0;
  var y = $($elemToScroll).offset().top + offset;
  $WINDOW.scrollTo($elemToScroll, {duration: speed, offset: offset});

});


// Menus
// -----

(function() {
  var $menus = $('.js-menu');

  if (IS_DESKTOP) {
    $menus.on('mouseenter.js-menu', 'li', function() {
      var $this = $(this);
      clearTimeout($this.data('hoverTimeout'));
      $this.addClass('is-hovered');
    });

    $menus.on('mouseleave.js-menu', 'li', function() {
      var $this = $(this);
      $this.data('hoverTimeout', setTimeout(function() {
        $this.removeClass('is-hovered');
      }, 200));
    });
  }

  if (IS_MOBILE) {
    $menus.on('click.js-m-menu', 'a', function(e) {
      e.preventDefault();

      var $anchor = $(this);
      var $parent = $anchor.parent();

      var hasDropdown = $parent.hasClass('has-dropdown');
      var isHovered = $parent.hasClass('is-hovered');

      $parent.siblings().removeClass('is-hovered');

      if (!hasDropdown) {
        location.href = $anchor.attr('href');
      } else {
        if (isHovered) {
          location.href = $anchor.attr('href');
        } else {
          $parent.addClass('is-hovered');
        }
      }
    });
  }
}());


// Tabs
// ----

$('.js-tabs .tabs-nav li a').click(function(e) {
  e.preventDefault();

  var $this = $(this);
  var $panel = $( $this.attr('href') );

  $this.closest('li').addClass('active').siblings().removeClass('active');
  $panel.closest('.tabs').find('.tabs-panel').hide();
  $panel.fadeIn();
});


// Galleries
// ---------

// init tosrus static gallery
$('.js-gallery').each(function() {
  $(this).find('.js-gallery-item').tosrus(TOSRUS_DEFAULTS);
});


// Rotators
// --------

$('.js-slideshow').each(function() {
  var $this = $(this);

  var tos = $this.tosrus({
    effect: 'slide',
    slides: {
      visible: 1
    },
    autoplay: {
      play: true,
      timeout: 7500
    },
    infinite: true,
    pagination: {
      add: true
    }
  });
});


// Scrolling to top
// ----------------

if ( !IS_MOBILE_WIDTH() ) {
  var goTopBtn = $('<div class="go-top-btn"></div>');
  goTopBtn.click(function() {
    $WINDOW.scrollTo(0, 200);
  });
  $WINDOW.scroll(function() {
    var scrollTop = $WINDOW.scrollTop();
    if ( scrollTop > 0 ) {
      goTopBtn.addClass('visible');
    } else {
      goTopBtn.removeClass('visible');
    }
  });
  $BODY.append( goTopBtn );
}


// HEADER
// ------

// close navigation menu after clicking on anchor
$('.cd-primary-nav > ul > li > a').on('click', function(){
  $('.cd-auto-hide-header').toggleClass('nav-open');
})

// manipulations with hash strings
$('.cd-primary-nav > ul > li > a').each(function( index ) {
  var url = $( this ).attr('href').substring(1, $( this ).attr('href').length);
  if (url[0] != '#') return;

  if (url.substr(url.length - 5) == 'popup'){

    // firing popups from the top menu
    $( this ).addClass('js-overlay');
  } else {

    // smooth scrolling to mainpage's anchors
    if (location.pathname == '/'){
      $( this ).addClass('js-scroll-to');
    }

  }
});

$('#header .btn_trigger').on('click', function(){
  document.getElementById('FormRequest_listen').checked     = false;
  document.getElementById('FormRequest_no_listen').checked  = false;
});


// HEADER
/* ---- панель с телефонами на мобильных --- */

// поведение устанавливается единожды при загрузке страницы
// событие ресайз не предусмотрено

if ($WINDOW.width() > 520) {
  $('.icon-callback').addClass('js-overlay');
  $('.icon-callback').removeClass('js-phones-panel');
} else {
  $('.icon-callback').removeClass('js-overlay');
  $('.icon-callback').addClass('js-phones-panel');
}


// нажатие на класс вызова панели-хелпера для мобильных
$('a.js-phones-panel').on('click', function(e){
  e.preventDefault();
  $('.phones-panel-mobile').fadeToggle('fast');
});

// вызов попапа = скрытие панели
$('a.btn_mobile-callback').on('click', function(){
  $('.phones-panel-mobile').fadeOut('fast');
});


// 01. WELCOME
// -----------
$('#welcome .btn_trigger').stop().hide();
$('#welcome .btn_trigger').css({'transition': 'none'});
$('#welcome .chain-list li').stop().hide();
$('#welcome .chain-list li').each(function( index ) {
  $( this ).stop().delay(333*index).fadeIn(350);
});
$('#welcome .btn_trigger').stop().delay(666).fadeIn(666);

$('#welcome .infographics-bg').delay(2500).animate({opacity: 1}, 650, 'swing', function(){ });
$('#welcome .infographics').delay(2750).animate({
  opacity: 1,
  left: 0}, 1200, 'swing', function(){});

$('#welcome .btn_trigger').on('click', function(){
  document.getElementById('FormRequest_listen').checked     = false;
  document.getElementById('FormRequest_no_listen').checked  = false;
});



// 02. ANIMATION
// -------------
var is_animation_started = false;
$WINDOW.on('scroll', function() {
  if ($('#animation .hype-anim_wrapper').hasClass('in-view')) {
    if (!is_animation_started) {
      var s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = '/i/hype/animationbanner_hype_generated_script.js?42251';
      var ss = document.getElementsByTagName('script')[0];
      ss.parentNode.insertBefore(s, ss);
      is_animation_started = true;
    }
  }
});

var isSlickSliderShown = false;

function setAppropriateAnimation() {
  if (isSlickSliderShown) return;

  $('.hype-anim_wrapper').hide();
  $('.alternative-animation-case').show();
  isSlickSliderShown = true;

  //http://kenwheeler.github.io/slick/#settings
  $(".animation-slider").slick({
    dots: false,
    fade: true,
    autoplay: true,
    autoplaySpeed: 700,
    draggable: false,
    centerMode: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    touchMove: false,
    nextArrow: '',
    prevArrow: ''
  });

}

$(window).on('resize', function () { setAppropriateAnimation(); });



// 04. REASONS
// -----------

$('#reasons .handset-decor').on('click', checkHandsetVisibility);

$WINDOW.on('scroll', function(){
  // событие появления только для этой секции
  if ( $('#reasons').hasClass('in-view') ){
    reasonSectionAppearance();
  }
});

/* IE alternative version */
var IE = navigator.appVersion.indexOf("Edge") !== -1 ||navigator.appVersion.indexOf("MSIE") !== -1 || navigator.userAgent.match(/Trident.*rv[ :]*11\./);
if (IE){
  $('.reason__tooltip, .reason__caption').css('display', 'none');
  $('.ie_reasons_wrapper').css('display', 'block');
}


var isReasonSectionHasBeenShown = false;
function reasonSectionAppearance(){

  if (isReasonSectionHasBeenShown == true) return;

  // InView animation
  // --------------------------
  // анимация соединительной линии
  $('#reasons .reason-line_1').delay(0).fadeIn(999);
  $('#reasons .reason-line_2').delay(150).fadeIn(999);
  $('#reasons .reason-line_3').delay(333).fadeIn(999);
  $('#reasons .reason-line_4').delay(500).fadeIn(999);
  $('#reasons .reason-line_5').delay(666).fadeIn(999);
  $('#reasons .reason-line_6').delay(999).fadeIn(999);

  // анимация нумерованных пунктов
  $('#reasons .reason-wrapper_1 .reason__number').delay(333).fadeIn(333);
  $('#reasons .reason-wrapper_2 .reason__number').delay(666).fadeIn(333);
  $('#reasons .reason-wrapper_3 .reason__number').delay(999).fadeIn(333);
  $('#reasons .reason-wrapper_4 .reason__number').delay(1333).fadeIn(333);

  // анимация телефонной трубки
  setTimeout( function(){ checkHandsetVisibility(); }, 1666 );
  $( window ).resize( checkHandsetVisibility );

  isReasonSectionHasBeenShown = true;
};

// показываем телефонную трубку разрешениях от 320-720px и свыше 1580px
function checkHandsetVisibility() {
  if (($(window).width() < 700) || ($(window).width() > 1580)) {
    $('#reasons .handset-decor').stop().delay(0).fadeIn(333);
    $('#reasons .handset-decor').stop().delay(0).animate({'margin-top': '-45'}, 450, function () {
      $('#reasons .handset-decor').stop().delay(0).animate({'margin-top': '-50'}, 333);
    });
  } else {
    $('#reasons .handset-decor').hide();
  }
}


(function() {

// 05. SERVICES
// ------------

  $('#services .services-slider-1').slick({
    slidesToShow: 5,
    infinite: false,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: true,
          asNavFor: '#services .services-slider-2, #services .services-slider-3'
        },
      },
      {
        breakpoint: 801,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: true,
          asNavFor: '#services .services-slider-2, #services .services-slider-3'
        },
      },
      {
        breakpoint: 569,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: true,
          adaptiveHeight: true,
          asNavFor: '#services .services-slider-2, #services .services-slider-3'
        },
      },
    ],
  });

  $('#services .services-slider-2').slick({
    slidesToShow: 5,
    infinite: false,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: true,
          asNavFor: '#services .services-slider-1, #services .services-slider-3'
        },
      },
      {
        breakpoint: 801,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: true,
          asNavFor: '#services .services-slider-1, #services .services-slider-3'
        },
      },
      {
        breakpoint: 569,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: true,
          adaptiveHeight: true,
          asNavFor: '#services .services-slider-1, #services .services-slider-3'
        },
      },
    ],
  });

  $('#services .services-slider-3').slick({
    slidesToShow: 5,
    infinite: false,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: true,
          asNavFor: '#services .services-slider-1, #services .services-slider-2'
        },
      },
      {
        breakpoint: 801,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: true,
          asNavFor: '#services .services-slider-1, #services .services-slider-2'
        },
      },
      {
        breakpoint: 569,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: true,
          adaptiveHeight: true,
          asNavFor: '#services .services-slider-1, #services .services-slider-2'
        },
      },
    ],
  });


  // set popup checkbox values when clicking specific service options
  //
  function resetFormRequestheckboxes(){
    document.getElementById('FormRequest_starting').checked = false;
    document.getElementById('FormRequest_optimal').checked = false;
    document.getElementById('FormRequest_special').checked = false;
    document.getElementById('FormRequest_expert').checked = false;
    document.getElementById('FormRequest_personal').checked = false;
  }

  $('.btn-option-1').on('click', function() {
    resetFormRequestheckboxes();
    document.getElementById('FormRequest_starting').checked = true;
  });
  $('.btn-option-2').on('click', function() {
    resetFormRequestheckboxes();
    document.getElementById('FormRequest_optimal').checked = true;
  });
  $('.btn-option-3').on('click', function() {
    resetFormRequestheckboxes();
    document.getElementById('FormRequest_special').checked = true;
  });
  $('.btn-option-4').on('click', function() {
    resetFormRequestheckboxes();
    document.getElementById('FormRequest_expert').checked = true;
  });
  $('.btn-option-5').on('click', function() {
    resetFormRequestheckboxes();
    document.getElementById('FormRequest_personal').checked = true;
  });


  function resetFormRequestReportCheckboxes(){
    document.getElementById('FormRequestReport_starting').checked = false;
    document.getElementById('FormRequestReport_optimal').checked = false;
    document.getElementById('FormRequestReport_special').checked = false;
    document.getElementById('FormRequestReport_expert').checked = false;
  }

  $('.btn-report-1').on('click', function() {
    resetFormRequestReportCheckboxes();
    document.getElementById('FormRequestReport_starting').checked = true;
  });
  $('.btn-report-2').on('click', function() {
    resetFormRequestReportCheckboxes();
    document.getElementById('FormRequestReport_optimal').checked = true;
  });
  $('.btn-report-3').on('click', function() {
    resetFormRequestReportCheckboxes();
    document.getElementById('FormRequestReport_special').checked = true;
  });
  $('.btn-report-4').on('click', function() {
    resetFormRequestReportCheckboxes();
    document.getElementById('FormRequestReport_expert').checked = true;
  });



// 06. STAGES
// ----------
//
// was replaced by inline code
//



// 07. TESTIMONIALS
// ----------------

$('#testimonials .cards-slider').slick({
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: PORTRAIT_TABLET_WIDTH + 1,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: SMALL_MOBILE_WIDTH + 1,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
});

$('#testimonials .card__quote').on('click', function(){
  var $obj = $(this);
  var $card = $obj.closest('.card');
  $card.find('.card__quote-detail').slideToggle();
  $card.find('.card__quote-more').fadeToggle();
});


$('#certificates .cards-slider').slick({
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  adaptiveHeight: false,
  responsive: [
    {
      breakpoint: PORTRAIT_TABLET_WIDTH + 1,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: SMALL_MOBILE_WIDTH + 1,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
});


// SUBSCRIBE
// ---------

$('.email__input').on('focus', function(){
  $('.email').addClass('email_focused');
});

$('.email__input').on('blur', function(){
  var val = document.getElementById('email__input').value;
  if( val == ''){
    $('.email').removeClass('email_focused');
  }
});


// FOOTER
// ------

// var section_contacts = $('#contacts');
// var parallax_animations_n = -1;
// $WINDOW.on('scroll', function(){

//   // decrease number of animations
//   if (++parallax_animations_n == 2){ parallax_animations_n = 0; }
//   if ((section_contacts.hasClass('in-view')) && (parallax_animations_n == 0))
//   {
//     // map parallax
//     var bH = $('body').height();
//     var sY = scrollY;
//     var wH = $WINDOW.height();
//     var dY = bH - sY - wH;
//     var y1 = -dY/5;
//     var y2 = -dY/10;
//     $('.map_big').stop().animate({  'background-position-y': y1}, 0, function (){});
//     $('.map_small').stop().animate({'background-position-y': y2}, 0, function (){});
//   }

//   // https://css-tricks.com/debouncing-throttling-explained-examples/
//   // https://developer.mozilla.org/ru/docs/DOM/window.requestAnimationFrame

// });


// IN-VIEW - анимации появления объектов при попадании в поле видимости
// --------------------------------------------------------------------

var $window = $(window);
var $animation_elements = $('._inview-watcher');

function check_if_in_view() {
  var window_height = $window.height();
  var window_top_position = $window.scrollTop();
  var window_bottom_position = (window_top_position + window_height);

  $.each($animation_elements, function () {
    var $element = $(this);
    var element_height = $element.outerHeight();
    var element_top_position = $element.offset().top;
    var element_bottom_position = (element_top_position + element_height);

    //check to see if this current container is within viewport
    if ((element_bottom_position >= window_top_position) &&
      (element_top_position <= window_bottom_position)) {
      $element.addClass('in-view');
    } else {
      //выход из поля зрения не отмечаем
      //$element.removeClass('in-view');
    }
  });
}
$window.on('scroll resize', check_if_in_view);
$window.trigger('scroll');


})();



// jQuery Uniloader - plugin fixes
// (вызов попапов в ОС Windows с появлением скроллбара)

var _welcome_block1_mod = false;
var _reasons_block1_mod = false;
var _services_block1_mod = false;
var _services_block2_mod = false;
var _contacts_block1_mod = false;

// Uniloader plugin code
// <---------------------
// Calculate dimensions of the browser window
function getWindowDimensions() {
  return {
    width: $(window)[0].innerWidth || $(window).width(),
    height: $(window)[0].innerHeight || $(window).height()
  };
};

// Calculate the scrollbar width (0 - no scrollbar)
function getScrollbarWidth() {
  var wd = getWindowDimensions();
  if ($(document).height() - wd.height <= 0) {
    return 0;
  }
  var parent = $('<div style="width:50px;height:50px;overflow:auto"><div/></div>').appendTo('body'),
    child = parent.children();
  var scrollbarWidth = child.innerWidth() - child.height(99).innerWidth();
  parent.remove();
  return scrollbarWidth;
};
// --------------------->

function fireFixScrollbarOffset_Begin() {
  var scrollbarWidth = getScrollbarWidth();

  $('#header').css('width', 'calc(100% - ' + scrollbarWidth + 'px)');
  $('#animation .section-title_wrapper').css('width', 'calc(100% - ' + scrollbarWidth + 'px)');
  $('#welcome .infographics-bg').css( 'width', 'calc(100% - ' + scrollbarWidth + 'px)');
  if ($WINDOW.width() > 800){
    _welcome_block1_mod = true;
    $('#welcome .wrapper_1').css( 'left', 'calc((100% - ' + scrollbarWidth + 'px) / 2 - 2px)');
  }
  if ($WINDOW.width() <= 720) {
    _reasons_block1_mod = true;
    $('#reasons .section-title_wrapper').css('width', 'calc(100% - 20px - ' + scrollbarWidth + 'px)');
  }
  $('#reasons .reason-lines_wrapper').css(  'width', 'calc(100% - 20px - ' + scrollbarWidth + 'px)');
  if ($WINDOW.width() >= 768){
    _services_block1_mod = true;
    $('#services .service_1').css('width', 'calc((100% - ' + scrollbarWidth + 'px) / 2)');
    $('#services .service_1').css('left',  '0');
    $('#services .service_2').css('width', 'calc((100% - ' + scrollbarWidth + 'px) / 2)');
    $('#services .service_2').css('left',  'calc((100% - ' + scrollbarWidth + 'px) / 2)');
  }
  if ($WINDOW.width() < 768){
    _services_block2_mod = true;
    $('#services .service_1').css('width', 'calc(100% - ' + scrollbarWidth + 'px)');
    $('#services .service_2').css('width', 'calc(100% - ' + scrollbarWidth + 'px)');
  }
  $('#stages .stage-lines_wrapper').css( 'width', 'calc(100% - 20px - ' + scrollbarWidth + 'px)');
  $('#stages .section-title').css( 'left', 'calc((100% - ' + scrollbarWidth + 'px) / 2)');
  $('#stages .step__title').css( 'left', 'calc((100% - ' + scrollbarWidth + 'px) / 2)' );

  $('#testimonials .section-title').css( 'left', 'calc((100% - ' + scrollbarWidth + 'px) / 2)' );

  if ($WINDOW.width() > 1024) {
    _contacts_block1_mod = true;
    var total_w = $WINDOW.width() - scrollbarWidth;
    var total_p = total_w * 0.635;
    $('#contacts .panel').css('width', 'calc(' + total_p + 'px)');
    $('#contacts .map').css('width', 'calc(100% - ' + scrollbarWidth + 'px)');
  }
}

window.fireFixScrollbarOffset_Begin = fireFixScrollbarOffset_Begin;

function fireFixScrollbarOffset_Release() {

  $('#header').attr('style', '');
  $('#animation .section-title_wrapper').attr('style', '');
  $('#welcome .infographics-bg').css('width', '100%');
  if (_welcome_block1_mod){ $('#welcome .wrapper_1').attr('style', ''); }
  if (_reasons_block1_mod){ $('#reasons .section-title_wrapper').attr('style', ''); }
  $('#reasons .section-title_wrapper').attr('style', '');
  $('#reasons .reason-lines_wrapper').attr('style', '');
  if (_services_block1_mod){ $('#services .service_1').attr('style', ''); }
  if (_services_block1_mod){ $('#services .service_2').attr('style', ''); }
  if (_services_block2_mod){ $('#services .service_1').attr('style', ''); }
  if (_services_block2_mod){ $('#services .service_2').attr('style', ''); }
  $('#stages .stage-lines_wrapper').css(  'width', 'calc(100% - 20px)');
  $('#stages .section-title').css( 'left', '50%' );
  $('#stages .step__title').css( 'left', '50%' );
  $('#testimonials .section-title').attr('style', '');
  if (_contacts_block1_mod){ $('#contacts .panel').attr('style', ''); }
  if (_contacts_block1_mod){ $('#contacts .map').css('width', '100%'); }

  _welcome_block1_mod = false;
  _reasons_block1_mod = false;
  _services_block1_mod = false;
  _services_block2_mod = false;
  _contacts_block1_mod = false;
}

window.fireFixScrollbarOffset_Release = fireFixScrollbarOffset_Release;


/* -===========  Book pre-order popup form  ===========- */
(function(){

  $('.book .prices .btn_option1').on('click', function(){
    document.getElementById('FormBook_demo').checked  = true;
    document.getElementById('FormBook_full').checked  = false;
    document.getElementById('FormBook_complect').checked  = false;
  });
  $('.book .prices .btn_option2').on('click', function(){
    document.getElementById('FormBook_demo').checked  = false;
    document.getElementById('FormBook_full').checked  = true;
    document.getElementById('FormBook_complect').checked  = false;
  });
  $('.book .prices .btn_option3').on('click', function(){
    document.getElementById('FormBook_demo').checked  = false;
    document.getElementById('FormBook_full').checked  = false;
    document.getElementById('FormBook_complect').checked  = true;
  });

  $('#FormBook_demo').on('change', function(){
    document.getElementById('FormBook_demo').checked  = true;
    document.getElementById('FormBook_full').checked  = false;
    document.getElementById('FormBook_complect').checked  = false;
  })
  $('#FormBook_full').on('change', function(){
    document.getElementById('FormBook_demo').checked  = false;
    document.getElementById('FormBook_full').checked  = true;
    document.getElementById('FormBook_complect').checked  = false;
  })
  $('#FormBook_complect').on('change', function(){
    document.getElementById('FormBook_demo').checked  = false;
    document.getElementById('FormBook_full').checked  = false;
    document.getElementById('FormBook_complect').checked  = true;
  })

})();

$(function(){
  // сбрасывает анимации, выполненные в Hype
  // служит для предотвращения развертывания крупной анимации в 1920
  $WINDOW.trigger('resize');
});


$(function(){
  $('.js-expandable-item .expandable-toggler').on('click', function(){
    $(this).closest('.js-expandable-item').toggleClass('is-expanded');
  });
});


$(function() {
  $('.services-info-list-slider').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    infinite: false,
    dots: true,
    arrows: true,
    focusOnSelect: true,
    asNavFor: '.services-info-slider',
    responsive: [
      {
        breakpoint: 801,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  $('.services-info-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    dots: false,
    arrows: false,
    asNavFor: '.services-info-list-slider',
    adaptiveHeight: true,
  });

  const url = new URL(document.location.href);
  const service = url.searchParams.get('service');

  if (service) {
    $('.services-info-slider').slick('slickGoTo', $(`[data-service-index="${service}"]`).index());
    $WINDOW.scrollTo($('#extra-services'), { offset: -100 });
  }
});


$('.js-check-service-for-calc-form').click(function() {
  let option = $(this).attr('href').split('?')[1].replace('service=', '');
  $('#calc-popup').find('input[type="checkbox"]').prop('checked', false).trigger('change');
  $('#' + option).prop('checked', true).trigger('change');
});


